<template>
  <div>
    <admin-dashboard />
  </div>
</template>

<script>
import adminDashboard from "@/components/dashboard/admin-dashboard.vue";

export default {
  components: {
    adminDashboard,
  },
};
</script>

<style></style>
