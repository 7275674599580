<template>
  <div>
    <div class="row pb-3">
      <div class="col-12">
        <h1>Статистика за період</h1>
      </div>
      <div class="col-12 col-md-4">
        <label> Від </label>
        <b-form-datepicker
          id="start-date"
          v-model="startDate"
          locale="uk"
          placeholder="Оберіть дату"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="col-12 col-md-4">
        <label> До </label>
        <b-form-datepicker
          id="start-date"
          v-model="endDate"
          locale="uk"
          placeholder="Оберіть дату"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="col-12 col-md-4">
        <label> &nbsp; </label>
        <div>
          <b-button variant="primary" class="w-100" @click="getStats()">
            Показати
          </b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12"></div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 class="mb-0">Користувачі</h2>
        <p v-if="filteredDates.startDate">
          {{ moment(filteredDates.startDate).format("DD.MM.YYYY") }} -
          {{ moment(filteredDates.endDate).format("DD.MM.YYYY") }}
        </p>
      </div>
      <div class="col-5 col-md-4">
        <b-overlay :show="isLoading">
          <statistic-card
            :statistic="usersRegistered.length || 0"
            :statisticTitle="'всього користувачів зареєстровано'"
            :icon="'bi-person-fill'"
          />
        </b-overlay>
      </div>
      <div class="col-5 col-md-4">
        <b-overlay :show="isLoading">
          <statistic-card
            :statistic="workersRegistered"
            :statisticTitle="'користувачів з роллю працівник'"
            :icon="'bi-person-fill'"
          />
        </b-overlay>
      </div>
      <div class="col-5 col-md-4">
        <b-overlay :show="isLoading">
          <statistic-card
            :statistic="adminsRegistered"
            :statisticTitle="'користувачів з роллю адміністратор'"
            :icon="'bi-person-fill'"
          />
        </b-overlay>
      </div>
      <div class="col-12">
        <h2 class="mb-0">Звернння</h2>
        <p v-if="filteredDates.startDate">
          {{ moment(filteredDates.startDate).format("DD.MM.YYYY") }} -
          {{ moment(filteredDates.endDate).format("DD.MM.YYYY") }}
        </p>
      </div>
      <div class="col-5 col-md-4">
        <b-overlay :show="isLoading">
          <statistic-card
            :statistic="tickets.length"
            :statisticTitle="'всього за період'"
            :icon="'bi-doc'"
          />
        </b-overlay>
      </div>
      <div class="col-5 col-md-4">
        <b-overlay :show="isLoading">
          <statistic-card
            :statistic="doneTickets.length"
            :statisticTitle="'зі статусом Виконано'"
            :icon="'file'"
          />
        </b-overlay>
      </div>
      <div class="col-5 col-md-4">
        <b-overlay :show="isLoading">
          <statistic-card
            :statistic="newTickets.length"
            :statisticTitle="'зі статусом Новий'"
            :icon="'file'"
          />
        </b-overlay>
      </div>

      <div class="col-12">
        <h2 class="mb-0">За департаментами</h2>
        <p v-if="filteredDates.startDate">
          {{ moment(filteredDates.startDate).format("DD.MM.YYYY") }} -
          {{ moment(filteredDates.endDate).format("DD.MM.YYYY") }}
        </p>
      </div>
      <div class="col-12">
        <b-card>
          <ul class="list">
            <li
              :class="{ bold: getForDepartment(d.id) > 0 }"
              v-for="d in departments"
              :key="d.id"
            >
              {{ d.name }}: {{ getForDepartment(d.id) }}
            </li>
          </ul>
        </b-card>
      </div>
      <div class="col-12 pt-3">
        <h2 class="mb-0">За темою</h2>
        <p v-if="filteredDates.startDate">
          {{ moment(filteredDates.startDate).format("DD.MM.YYYY") }} -
          {{ moment(filteredDates.endDate).format("DD.MM.YYYY") }}
        </p>
      </div>
      <div class="col-12">
        <b-card>
          <ul class="list">
            <li
              :class="{ bold: getForTopic(t.name) > 0 }"
              v-for="(t, i) in topics"
              :key="i"
            >
              {{ t.name }}: {{ getForTopic(t.name) }}
            </li>
          </ul>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import statisticCard from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import chartCard from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
export default {
  components: {
    statisticCard,
    chartCard,
  },
  data() {
    return {
      moment: moment,
      isLoading: false,
      stats: {},
      startDate: null,
      endDate: null,
      filteredDates: {
        startDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    usersByDate() {
      let users = [];
      if (this.usersRegistered && this.usersRegistered.length > 0) {
        this.usersRegistered.forEach((user) => {
          const date = new Date(user.created_at);
          const dateStr = date.toDateString();
          const found = users.find((el) => {
            return el.date == dateStr;
          });
          if (found) {
            found.number += 1;
          } else {
            users.push({
              date: dateStr,
              number: 1,
              is_admin: user.is_admin,
              is_worker: user.is_worker,
            });
          }
        });
      }

      const data = [
        {
          name: "Користувачі",
          data: users.map((el) => {
            return {
              x: el.date,
              y: el.number,
            };
          }),
        },
        // the same for admins only
        {
          name: "Адміністратори",
          data: users
            .filter((el) => {
              return el.is_admin;
            })
            .map((el) => {
              return {
                x: el.date,
                y: el.number,
              };
            }),
        },
        //the same for workers only
        {
          name: "Працівники",
          data: users
            .filter((el) => {
              return el.is_worker;
            })
            .map((el) => {
              return {
                x: el.date,
                y: el.number,
              };
            }),
        },
      ];
      return data;
    },
    usersRegistered() {
      let users = [];
      if (this.stats.users && this.stats.users.length > 0) {
        users = this.stats.users;
      }
      users.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
      return users;
    },
    adminsRegistered() {
      let number = 0;
      if (this.stats.users && this.stats.users.length > 0) {
        const admins = this.stats.users.filter((el) => {
          return el.is_admin == true;
        });
        number = admins.length;
      }
      return number;
    },
    workersRegistered() {
      let number = 0;
      if (this.stats.users && this.stats.users.length > 0) {
        const workers = this.stats.users.filter((el) => {
          return el.is_worker == true;
        });
        number = workers.length;
      }
      return number;
    },
    tickets() {
      return this.stats.tickets || [];
    },
    doneTickets() {
      return this.stats.tickets.filter((el) => {
        return el.status == 1;
      });
    },
    newTickets() {
      return this.stats.tickets.filter((el) => {
        return el.status == 0;
      });
    },
    datasets() {
      return this.$store.getters["getDatasets"] || {};
    },
    departments() {
      return this.datasets.departments || [];
    },
    topics() {
      return this.datasets.topics || [];
    },
  },
  methods: {
    async getStats() {
      let startDate = this.startDate;
      let endDate = this.endDate;
      this.isLoading = true;
      await this.$api
        .post("/dashboard/getAdminStats/", {
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          this.stats = response.data;
          this.filteredDates.startDate = startDate;
          this.filteredDates.endDate = endDate;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getForDepartment(departmentId) {
      let tickets = this.stats.tickets.filter((el) => {
        return el.department == departmentId;
      });
      return tickets.length;
    },
    getForTopic(topicName) {
      let tickets = this.stats.tickets.filter((el) => {
        return el.topic == topicName;
      });
      return tickets.length;
    },
  },
  mounted() {
    let now = new Date();
    let monthBefore = new Date();
    monthBefore.setMonth(monthBefore.getMonth() - 1);
    this.startDate = monthBefore;
    this.endDate = now;
    this.getStats();
  },
};
</script>

<style lang="scss" scoped>
.list {
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 0.3rem;
    &.bold {
      font-weight: bold;
    }
  }
}
</style>
